import {
  IAddProductReviewRequest,
  IBreadCrumbsRequest,
  ICategoryRequest,
  IPopularProductRequest,
  IProductRequest,
  IProductReviewRequest,
  IProductsCompilationResponse,
} from './product.serviceTypes';

import { IBaseResponse } from 'common/types/interfaces/IBaseResponse';
import { baseConfigService } from 'services/baseConfig.service';
import {
  IResponseGetBreadCrumbs,
  IResponseGetProductCardId,
  IResponseGetProductReviews,
  IResponseProductReviewsGrades,
} from 'store/reducers/productSlice/types';
import { IProductsListResponse } from 'store/reducers/supplier/product';
import {
  IBrandsInfo,
  IProductFilterParams,
  IProductSorting,
} from 'store/reducers/supplier/product/types';

export const productService = {
  getList: async ({
    ascending,
    category_id,
    limit,
    offset,
    sort,
    query,
  }: ICategoryRequest) => {
    const categoryIds: number[] = [];
    const params = { offset, limit, sort, ascending, category_ids: categoryIds, query };

    if (category_id !== 'all') {
      categoryIds.push(+category_id);
    }
    const { data } = await baseConfigService.get<
      IBaseResponse<IProductsCompilationResponse>
    >(`products`, { params });

    return data.result;
  },

  getBreadCrumbs: async ({ category_id }: IBreadCrumbsRequest) => {
    const { data } = await baseConfigService.get<
      IBaseResponse<IResponseGetBreadCrumbs[]>
    >(`categories/${category_id}/breadcrumbs`);

    return data.result;
  },

  getProductById: async ({ product_id }: IProductRequest) => {
    const { data } = await baseConfigService.get<
      IBaseResponse<IResponseGetProductCardId>
    >(`products/${product_id}`);

    return data.result;
  },

  addFavorite: async (params: IProductRequest) => {
    const { data } = await baseConfigService.post<IBaseResponse<boolean>>(
      `sellers/favorites/add`,
      { product_id: params.product_id },
      {
        params,
      },
    );

    return data.result;
  },

  removeFavorite: async (params: IProductRequest) => {
    const { data } = await baseConfigService.delete<IBaseResponse<boolean>>(
      `sellers/favorites/remove`,
      {
        params,
      },
    );

    return data.result;
  },

  getProductImagesById: async ({ product_id }: IProductRequest) => {
    const { data } = await baseConfigService.get(`products/${product_id}/images`, {});

    return data.result;
  },

  getPopularProduct: async (params: IPopularProductRequest) => {
    const { data } = await baseConfigService.get(`products/popular`, {
      params,
    });

    return data;
  },

  getReviewGradesByProductId: async ({ product_id }: IProductRequest) => {
    const { data } = await baseConfigService.get<IResponseProductReviewsGrades>(
      `products/${product_id}/reviews/grades`,
    );

    return data.result;
  },

  deleteProducts: async (selectedProductIds: number[]) => {
    const { data } = await baseConfigService.post<IBaseResponse<boolean>>(
      `suppliers/products/delete`,
      [...selectedProductIds],
    );

    return data.result;
  },

  restoreList: async (productsId: number[]) => {
    const { data } = await baseConfigService.post<IBaseResponse<boolean>>(
      `suppliers/products/restore`,
      [...productsId],
    );

    return data.result;
  },

  getListSupplierProducts: async (
    body: IProductFilterParams,
    params: IProductSorting,
  ) => {
    const { data } = await baseConfigService.post<IBaseResponse<IProductsListResponse>>(
      `suppliers/products`,
      body,
      { params },
    );

    return data.result;
  },

  getBrandsInfo: async () => {
    const { data } = await baseConfigService.get<IBaseResponse<IBrandsInfo[]>>(`brands`);

    return data.result;
  },

  getProductsReviewsById: async ({
    product_id,
    offset,
    limit,
    with_photos,
    grade,
  }: IProductReviewRequest) => {
    const params = { offset, limit, with_photos, grade };
    const { data } = await baseConfigService.get<
      IBaseResponse<IResponseGetProductReviews>
    >(`/products/${product_id}/reviews`, { params });

    return data.result;
  },

  addProductReview: async ({
    product_id,
    product_review_photo,
    product_review_text,
    product_review_grade,
  }: IAddProductReviewRequest) => {
    const params = { product_review_grade, product_review_photo, product_review_text };

    return baseConfigService.post<IBaseResponse<boolean>>(
      `/products/${product_id}/reviews/add`,
      params,
    );
  },
};
